import React, { useState, Fragment } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl-next';

import ModalTransition from '@atlaskit/modal-dialog/modal-transition';
import Tooltip from '@atlaskit/tooltip/Tooltip';
import DefaultAppIcon from '@atlaskit/icon/core/app';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import AppsIcon from '@atlaskit/icon/core/apps';
import { Text } from '@atlaskit/primitives';
import { IconButton } from '@atlaskit/button/new';
import ShowMoreIcon from '@atlaskit/icon/utility/show-more-horizontal';

import { MenuLinkItem } from '@atlassian/navigation-system/side-nav/menu-link-item';
import { MenuList } from '@atlassian/navigation-system/side-nav/menu-list';
import {
	ExpandableMenuItem,
	ExpandableMenuItemTrigger,
	ExpandableMenuItemContent,
} from '@atlassian/navigation-system/side-nav/expandable-menu-item';

import { useRouteActions, useRouteUrl } from '@confluence/route-manager';
import { PageSegmentLoadEnd } from '@confluence/browser-metrics';
import { FallbackableImage } from '@confluence/fallbackable-image';
import { ForgeKeyboardShortcut, ForgeKeyboardShortcutVisualizer } from '@confluence/forge-ui';
import { LoadableAfterPaint } from '@confluence/loadable';
import { useSpacePersistentExpansion } from '@confluence/collapsible-section/entry-points/useSpacePersistentExpansion';

import { SPACE_APPS_METRIC } from './perf.config';
import { isConnectApp, isForgeApp } from './space-apps-helpers';
import type { SpaceNavigationQuery_spaceSidebarLinks_main } from './__types__/SpaceNavigationQuery';
import { SpaceSidebarLinkType } from './__types__/SpaceNavigationQuery';

type SpaceAppsProps = {
	isSpaceAdmin: boolean;
	allAppLinks: any[];
	allVisibleAppLinks: any[];
	spaceKey: string;
};

export const SpaceApps = ({
	isSpaceAdmin,
	allAppLinks,
	allVisibleAppLinks,
	spaceKey,
}: SpaceAppsProps) => {
	const { isExpanded, onExpansionToggle } = useSpacePersistentExpansion({
		sectionName: 'apps',
	});
	const [isOpenAppsConfiguration, setOpenAppsConfiguration] = useState(false);
	const hasVisibleAppLinks = allVisibleAppLinks.length !== 0;

	if (allAppLinks.length === 0 || (!hasVisibleAppLinks && !isSpaceAdmin)) {
		return null;
	}

	const pageSegmentLoadCustomData = {
		connectApps: allVisibleAppLinks.filter((a) => isConnectApp(a)).length,
		forgeApps: allVisibleAppLinks.filter((a) => isForgeApp(a)).length,
	};

	return (
		<>
			<PageSegmentLoadEnd
				key={spaceKey}
				metric={SPACE_APPS_METRIC}
				customData={pageSegmentLoadCustomData}
			/>
			<ExpandableMenuItem isExpanded={isExpanded} onExpansionToggle={onExpansionToggle}>
				<ExpandableMenuItemTrigger
					elemBefore={<AppsIcon label="" color="currentColor" />}
					actionsOnHover={
						isSpaceAdmin && (
							<Tooltip content={<FormattedMessage {...i18n.editButtonTooltip} />} position="bottom">
								<IconButton
									icon={ShowMoreIcon}
									label={<FormattedMessage {...i18n.editButtonLabel} />}
									onClick={() => setOpenAppsConfiguration(true)}
									spacing="compact"
									appearance="subtle"
								/>
							</Tooltip>
						)
					}
				>
					<FormattedMessage {...i18n.title} />
				</ExpandableMenuItemTrigger>
				<ExpandableMenuItemContent>
					{hasVisibleAppLinks ? (
						<MenuList>
							{allVisibleAppLinks.map((link) => (
								<AppLink key={link.id} link={link} />
							))}
						</MenuList>
					) : (
						<Text color="color.text.subtle">
							<FormattedMessage {...i18n.noAppsMessage} />
						</Text>
					)}
				</ExpandableMenuItemContent>
			</ExpandableMenuItem>
			<ModalTransition>
				{isOpenAppsConfiguration ? (
					<LazySpaceAppsConfiguration
						links={allAppLinks}
						spaceKey={spaceKey}
						onCancel={() => setOpenAppsConfiguration(false)}
						onSave={() => setOpenAppsConfiguration(false)}
					/>
				) : (
					<Fragment />
				)}
			</ModalTransition>
		</>
	);
};

type AppLinkProps = {
	link: SpaceNavigationQuery_spaceSidebarLinks_main;
};

const AppLink = ({ link }: AppLinkProps) => {
	const { id, icon, url, title, type } = link;
	const isMatchingCurrentUrlArgs = {
		selector: (actualUrl: string | undefined) => actualUrl === url,
	};
	const matchesUrl = useRouteUrl(isMatchingCurrentUrlArgs);
	const { push } = useRouteActions();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const onClick = () => {
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'button',
				source: 'sidebar',
				actionSubjectId: 'appLink',
				attributes: {
					appTitle: title,
				},
			},
		}).fire();
	};

	const linkItem = (
		<MenuLinkItem
			key={id}
			elemBefore={
				<FallbackableImage
					data-vc="space-apps-link-icon"
					src={icon?.path}
					width="16"
					height="16"
					fallback={DefaultAppIcon}
					role="presentation"
				/>
			}
			isSelected={Boolean(url && matchesUrl)}
			href={url || ''}
			onClick={onClick}
		>
			{title}
		</MenuLinkItem>
	);

	return type === SpaceSidebarLinkType.FORGE ? (
		<ForgeKeyboardShortcutVisualizer key={id} module={link as any}>
			{linkItem}
			{url && <ForgeKeyboardShortcut key={id} module={link as any} action={() => push(url)} />}
		</ForgeKeyboardShortcutVisualizer>
	) : (
		linkItem
	);
};

const LazySpaceAppsConfiguration = LoadableAfterPaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-SpaceAppsConfiguration" */ './SpaceAppsConfiguration'
			)
		).SpaceAppsConfiguration,
});

const i18n = defineMessages({
	title: {
		id: 'side-navigation.space-apps.header',
		defaultMessage: 'Space apps',
		description: 'Header for a list of apps in a space',
	},
	editButtonLabel: {
		id: 'side-navigation.space-apps.edit.label',
		defaultMessage: 'Edit apps',
		description: 'Label for the edit space apps button',
	},
	editButtonTooltip: {
		id: 'side-navigation.space-apps.edit.tooltip',
		defaultMessage: 'Reorder, show and hide apps',
		description: 'Tip describing what a edit button will do',
	},
	noAppsMessage: {
		id: 'side-navigation.space-apps.no-space-apps.message',
		defaultMessage: 'No apps showing in the sidebar',
		description: 'Message when apps section is empty because a space admin has hidden all apps',
	},
});
